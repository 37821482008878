<template>
  <el-card class="box-card">
    <div slot="header">
      <span>{{ $tc('entity.myAccount', 2) }}</span>
    </div>
    <el-tabs tab-position="left" style="height: 400px">
      <el-tab-pane label="Usuario">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form
              ref="formUser"
              :model="user"
              :rules="rules"
              :status-icon="true"
            >
              <el-form-item :label="$t('firstName')" prop="userFirstName">
                <el-input
                  v-model="user.userFirstName"
                  :maxlength="80"
                ></el-input>
              </el-form-item>

              <el-form-item :label="$t('lastName')" prop="userLastName">
                <el-input
                  v-model="user.userLastName"
                  :maxlength="80"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  size="mini"
                  type="primary"
                  :loading="loadingForm"
                  @click.prevent="updateUser"
                >
                  {{ $t('save') }}
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Seguridad">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form
              ref="formSecurity"
              :model="userSecurity"
              :rules="rulesSecurity"
              :status-icon="true"
            >
              <el-form-item :label="$t('currentPassword')" prop="userPassword">
                <el-input
                  v-model="userSecurity.passwordCurrent"
                  type="password"
                  :maxlength="32"
                  auto-complete="off"
                ></el-input>
              </el-form-item>

              <el-form-item :label="$t('password')" prop="passwordNew">
                <el-input
                  v-model="userSecurity.passwordNew"
                  type="password"
                  :maxlength="32"
                  auto-complete="off"
                ></el-input>
              </el-form-item>

              <el-form-item
                :label="$t('confirmPassword')"
                prop="passwordNewCheck"
              >
                <el-input
                  v-model="userSecurity.passwordNewCheck"
                  type="password"
                  :maxlength="32"
                  auto-complete="off"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  size="mini"
                  type="primary"
                  :loading="loadingForm"
                  @click.prevent="updatePassword"
                >
                  {{ $t('changePassword') }}
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="E-mail">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form
              ref="formEmail"
              :model="user"
              :rules="rulesEmail"
              :status-icon="true"
            >
              <el-form-item label="E-mail" prop="userEmail">
                <el-input v-model="user.userEmail" :maxlength="160"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="currentEmail === user.userEmail"
                  :loading="loadingForm"
                  @click.prevent="updateEmail"
                >
                  {{ $t('change') }}
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Telefono">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form
              ref="formPhone"
              :model="user"
              :rules="rulesPhone"
              :status-icon="true"
            >
              <el-form-item :label="$t('cellPhone')" prop="userPhone">
                <el-input-number
                  v-model="user.userPhone"
                  :controls="false"
                  auto-complete="off"
                ></el-input-number>
              </el-form-item>

              <el-form-item>
                <el-button
                  size="mini"
                  type="primary"
                  :loading="loadingForm"
                  @click.prevent="updatePhone"
                >
                  {{ $t('save') }}
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import axios from 'axios'
import { i18n } from '../../i18n'

// import router from '../../router/'
import userEntity from '../user/model'

// import uuid from 'uuid'
export default {
  components: {},
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingresa la nueva contraseña'))
      } else {
        if (this.userSecurity.passwordNewCheck !== '') {
          this.$refs.formSecurity.validateField('passwordNewCheck')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Ingresa la contraseña nuevamente'))
      } else if (value !== this.userSecurity.passwordNew) {
        callback(new Error('La contraseña no es igual!'))
      } else {
        callback()
      }
    }
    const validateEmailIsAliaddo = (rule, value, callback) => {
      /* eslint-disable-next-line no-useless-escape */
      const regularExp =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (value !== '') {
        if (value.includes('@aliaddo.com') && regularExp.test(value)) {
          callback()
        } else {
          callback(new Error(rule.message))
        }
      } else {
        callback(new Error(rule.message))
      }
    }

    return {
      user: userEntity(),
      userSecurity: {
        userId: '',
        passwordCurrent: '',
        passwordNew: '',
        passwordNewCheck: '',
      },
      currentEmail: JSON.parse(localStorage.getItem('masters')).email,
      loading: false,
      loadingForm: false,
      rules: {
        userFirstName: [
          { required: true, message: 'El campo es requerido', trigger: 'blur' },
        ],
        userLastName: [
          { required: true, message: 'El campo es requerido', trigger: 'blur' },
        ],
        userEmail: [
          {
            type: 'email',
            required: true,
            message: 'El e-mail es requerido',
            trigger: 'blur',
          },
        ],
        userPhone: [
          {
            required: true,
            message: 'El numero telefonico es requerido',
            trigger: 'blur',
          },
        ],
      },
      rulesEmail: {
        userEmail: [
          {
            type: 'email',
            required: true,
            message: 'El e-mail es requerido',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: i18n.t('noAliaddoEmail'),
            validator: validateEmailIsAliaddo,
          },
        ],
      },
      rulesPhone: {
        userPhone: [
          {
            required: true,
            message: 'El numero telefonico es requerido',
            trigger: 'blur',
          },
        ],
      },
      rulesSecurity: {
        passwordCurrent: [
          { required: true, message: 'El campo es requerido', trigger: 'blur' },
        ],
        passwordNew: [{ validator: validatePass, trigger: 'blur' }],
        passwordNewCheck: [{ validator: validatePass2, trigger: 'blur' }],
      },
    }
  },
  mounted() {
    const _this = this
    _this.get()
  },
  methods: {
    get() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(`users/${JSON.parse(localStorage.getItem('masters')).userId}`)
        .then(
          response => {
            _this.user = response.body
            _this.loading = false
          },
          response => {
            _this.loading = false
          },
        )
    },
    updateUser() {
      const _this = this
      _this.$refs.formUser.validate(valid => {
        if (valid) {
          _this.loadingForm = true
          _this.$http.put('users/updateAccount', _this.user).then(
            response => {
              _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
              _this.loadingForm = false
              _this.$refs.saveForm.resetFields()
            },
            response => {
              _this.loadingForm = false
            },
          )
        }
      })
    },
    updateEmail() {
      const _this = this
      _this.$refs.formEmail.validate(valid => {
        if (valid) {
          _this.loadingForm = true
          _this.$http.put('users/updateAccountEmail', _this.user).then(
            response => {
              _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
              _this.loadingForm = false
              _this.$refs.saveForm.resetFields()
            },
            response => {
              _this.loadingForm = false
            },
          )
        }
      })
    },
    updatePhone() {
      const _this = this
      _this.$refs.formPhone.validate(valid => {
        if (valid) {
          _this.loadingForm = true
          _this.$http.put('users/updateAccountPhone', _this.user).then(
            response => {
              _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
              _this.loadingForm = false
              _this.$refs.saveForm.resetFields()
            },
            response => {
              _this.loadingForm = false
            },
          )
        }
      })
    },
    updatePassword() {
      const _this = this
      _this.$refs.formSecurity.validate(valid => {
        if (valid) {
          _this.loadingForm = true
          _this.userSecurity.userId = _this.user.id
          _this.$http
            .post('users/UpdatePasswordFromUser', _this.userSecurity)
            .then(
              response => {
                _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')

                // _this.$message({ showClose: true, message: i18n.t('processSuccess'), type: 'success' })
                _this.loadingForm = false
                _this.$refs.formSecurity.resetFields()
              },
              response => {
                _this.loadingForm = false
              },
            )
        }
      })
    },
    sendCodePhone() {
      const _this = this
      const code = '774455'
      _this.user.userPhone = '3103014130'
      axios
        .get(
          `http://sms.simlat.com.co/smsapi/pushsms.aspx?user=ALIADDO&password=ALIADDO&msisdn=${_this.user.userPhone}&sid=3503166000&msg=Usar ${code} como codigo de confirmacion del numero telefonico. ALIADDO&fl=0`,
        )
        .then(response => {
          console.log('sms', response)
        })
        .catch(e => {
          _this.$message.error(e.response)
        })
    },
  },
}
</script>
