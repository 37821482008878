export default () => ({
  id: '00000000-0000-0000-0000-000000000000',
  userName: '',
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPhone: '',
  userStatus: 'Activo',
  serviceId: '',
  companyKey: '',
  companyId: '00000000-0000-0000-0000-000000000000',
  roleId: '',
})

export function profileModel() {
  return {
    serviceId: -1,
    companyKey: 0,
    roleId: 0,
  }
}
